<template>
  <div class="rounded-10 h-auto bg-white w-full pt-8 pb-8 pl-8">
    <div class="mt-4">
      <div class="all">
        <div class="w-full flex text-90 text-c18 font-c5 text-left mb-6">
          <div class="w-1/6">
            Nº DE RÉF.
          </div>
          <div class="w-1/4 ml-4">
            CLIENT
          </div>
          <div class="w-1/6 ml-4">
            DATE
          </div>
          <div class="w-1/6 ml-4 text-left">
            STATUT
          </div>
          <div class="w-1/6 ml-4">
            MONTANT
          </div>
        </div>

        <div class="diviser" />

        <div
          v-if="charge"
          class="flex justify-center mt-16"
        >
          <easy-spinner
            type="dots"
            size="70"
          />
        </div>

        <div v-if="tracker.length === 0 && !charge" class="mt-10 text-90 text-c18">
          Aucune donnée disponible pour le moment
        </div>

        <div
          v-for="(item, index) in tracker"
          :key="index"
          class="mt-5"
        >
          <div
            v-if="index > 0"
            class="diviser mt-5"
          />
          <div @click="selected(item)">
            <liste
              class="mt-5"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt-10 justify-end pb-16 pr-10">
      <div class="w-1/3 flex items-center justify-end">
        <div class="text-c16 font-c6">
          Page {{ page }} sur {{ totalPage }}
        </div>
        <icon
            :data="icons.prev"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="prev"

        />
        <icon
            :data="icons.next"
            height="50"
            width="50"
            class="icone cursor-pointer ml-4"
            original
            @click="next"
        />
      </div>
    </div>
  </div>
</template>

<script>
import arrow from '../../../assets/icons/arrow.svg'
import agenda from '../../../assets/icons/agenda.svg'
import prev from '../../../assets/icons/prev.svg'
import next from '../../../assets/icons/next.svg'
import down from '../../../assets/icons/down.svg'
import liste from './liste'
import apiroutes from '../../../router/api-routes'
import http from '../../../plugins/https'

export default {
  name: "Index",
  components: {
    liste
  },

  props: {
    filtre: {
      type: String,
      default: null
    },
    searchType: {
      type: String,
      default: null
    },
    search: {
      type: String,
      default: null
    }
  },


  data () {
    return {
      icons: {
        arrow,
        agenda,
        down,
        next,
        prev
      },
      devis: [],
      charge: true,
      temoin: null,

      page: 1,
      limite: 20,
      total: 0,
      totalPage: 0,
      devisPaginate: []
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    tracker: function () {
      let retour = this.devisPaginate

      if (this.filtre !== 'Filtrer ici' &&
          this.filtre !== 'Tout les devis' ||
          this.search !== '' &&
          this.search !== null
      ) {
        if (this.devis.length > 0){
          retour = this.devis
        }

        if (this.filtre === 'En cours'){
          retour = retour.filter((item) => {
            if (item.state === 'IN_PROGRESS' &&
                this.conversion(3, item) === false &&
                this.expiration(item) === false &&
                item.order === null
            ) {
              return item
            }
          })
        }

        if (this.filtre === 'Accepté'){
          retour = retour.filter((item) => {
            if (item.order !== null) {
              return item
            }
          })
        }

        if (this.filtre === 'A rappeler'){
          retour = retour.filter((item) => {
            if (item.state === 'IN_PROGRESS' &&
                this.conversion(3, item) === true &&
                this.expiration(item) === false &&
                item.order === null
            ) {
              return item
            }
          })
        }

        if (this.filtre === 'Devis expiré'){
          retour = retour.filter((item) => {
            if (item.state === 'IN_PROGRESS' && this.expiration(item) === true && item.order === null) {
              return item
            }
          })
        }

        if (this.search !== null && this.search !== '') {
          const type = this.search.toLowerCase()
          retour = retour.filter((item) => {
            // Use full option
            if ((item.state.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                (item.reference.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                ((item.client.name + ' ' + item.client.surname).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                ((item.client.surname + ' ' + item.client.name).normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ||
                (item.agency.label.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
              return item
            }

            // console.log(this.searchType)
            // Use Search type
            if (this.searchType !== 'Type de recherche' && this.searchType !== 'Aucun') {
              if (this.searchType === 'Date d\'édition' &&
                  (new Date(item.created_at).toLocaleDateString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }
              if (this.searchType === 'Date d\'expiration' &&
                  (new Date(item.expireDate).toLocaleDateString().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()).indexOf(type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1) {
                return item
              }
            }
          })
          this.$emit('user', retour.length)
        }
        this.$emit('user', retour.length)
      }

      return retour
    }
  },

  created () {
    this.allEstmatePaginate()
    this.allEstmate()
  },

  methods: {
    prev () {
      if (this.page > 1) {
        this.page = this.page - 1
        this.allEstmatePaginate()
        this.temoin = this.filtre
      }
    },

    next () {
      if (this.totalPage > this.page) {
        this.page = this.page + 1
        this.allEstmatePaginate()
        this.temoin = this.filtre
      }
    },

    allEstmate () {
      http.get(apiroutes.baseURL + apiroutes.allDevis + '?filter=AGENCY')
          .then(response => {
            // console.log('ckskd')
            // console.log(response)
            this.devis = response
            this.charge = false
            this.$emit('user', response.length)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.status === 401) {
              window.location.reload()
            }
          })
    },

    allEstmatePaginate () {
        http.post(apiroutes.baseURL + apiroutes.devisPaginate, {
          "page": this.page,
          "limit": this.limite,
          "filter": "AGENCY"
        })
          .then(response => {
            // console.log(response.data)
            this.devisPaginate = response.data
            this.charge = false
            this.total = response.total
            const page = parseInt(this.total)/parseInt(this.limite)

            if (page > parseInt(page)) {
              this.totalPage = parseInt(page) + 1
            } else {
              this.totalPage = parseInt(page)
            }
            this.$emit('user', response.total)
          })
          .catch(error => {
            this.charge = false
            console.log(error)
            if (error.status === 401) {
              window.location.reload()
            }
          })
    },

    selected (item) {
      const url = window.location.origin + '/detailsDevis?x=' + item.id
      window.open(url, "_blank");
      // this.$router.push({ path: '/detailsDevis', query: { x: item.id } })
    },

    conversion (dure, item) {
      const temps = dure * (1000 * 3600 * 24)
      const count = new Date(item.created_at).getTime() + temps
      const now = new Date().getTime()
      if (now >= count ) {
        return true
      } else {
        return false
      }
    },

    expiration (item) {
      const now = new Date().getTime()
      const expire = new Date(item.expireDate).getTime()

      if (now >= expire) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.filtr{
  background: rgba(242, 245, 249, 0.5);
  border: 0.5px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  height: 36.39px;
}
.compFiltre{
  width: auto;
  min-width: 9.1%;
}
.diviser {
  height: 0.5px;
  background-color: #DDDDDD;
}
</style>
