<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/6">
        {{ item.reference }}
      </div>

      <div class="w-1/4 flex items-center ml-4">
        <nameLabel
          :first="item.client.name"
          :second="item.client.surname"
          height="42px"
          width="42px"
        />
        <div class="ml-4">
          <div class="text-c16">
            {{ item.client.name }}  {{ item.client.surname }}
          </div>
        </div>
      </div>

      <div class="w-1/6 ml-4">
        {{ new Date(item.created_at).toLocaleDateString() }}
      </div>

      <div class="w-1/5 ml-4">
        <bouton
            label="En cours"
            height="32px"
            color="#FBA705"
            background="#FFF6E6"
            radius="10px"
            size="16px"
            v-if="item.state === 'IN_PROGRESS' && conversion(3) === false && expiration() === false && item.order === null"
            class="w-1/2"
        />
        <bouton
            label="Accepté"
            height="32px"
            color="#00C24E"
            background="#E5F9ED"
            radius="10px"
            size="16px"
            v-if="item.order !== null"
            class="w-1/2"
        />

        <bouton
            label="A rappeler"
            height="32px"
            color="#F82626"
            background="#FEE9E9"
            radius="10px"
            size="16px"
            v-if="item.state === 'IN_PROGRESS' && conversion(3) === true && expiration() === false && item.order === null"
            class="w-1/2"
        />

        <bouton
            label="Devis expiré"
            height="32px"
            color="#27053F"
            background="#E9E6EC"
            radius="10px"
            size="16px"
            v-if="item.state === 'IN_PROGRESS' && expiration() === true && item.order === null"
            class="w-1/2"
        />
      </div>

      <div class="w-1/6 ml-4">
        {{ item.financialData.totalAmount.toLocaleString() }} F
      </div>
    </div>
  </div>
</template>

<script>
import entreprise from '../../../assets/icons/entreprise.svg'
import email from '../../../assets/icons/email.svg'
import down from '../../../assets/icons/down.svg'
import nameLabel from '../../helper/add/nameLabel'
import bouton from '../../helper/add/button'

export default {
  name: "Index",
  components: {
    nameLabel,
    bouton
  },

  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      icons: {
        entreprise,
        email,
        down
      },
    }
  },

  computed: {
    amount: function () {
      let amount = (this.item.financialData.totalAmount + this.item.financialData.deliveryAmount)-(this.item.financialData.discountAmount + this.item.financialData.supportAmount)
      return amount.toLocaleString()
    }
  },

  methods: {
    conversion (dure) {
      const temps = dure * (1000 * 3600 * 24)
      const count = new Date(this.item.created_at).getTime() + temps
      const now = new Date().getTime()
      if (now >= count ) {
        return true
      } else {
        return false
      }
    },

    expiration () {
      const now = new Date().getTime()
      const expire = new Date(this.item.expireDate).getTime()

      if (now >= expire) {
        return true
      } else {
        return false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
