<template>
  <div class="p-10 pr-16">
    <div>
      <div
        class="flex items-center"
      >
        <div class="w-2/5 flex text-left text-c48 items-center font-c6">
          <icon
            :data="icons.back"
            height="70"
            width="70"
            class="mr-2 cursor-pointer"
            original
          />
          <div class="ml-4">
            Devis <span v-if="user !== null">({{ user }})</span>
          </div>
        </div>

        <div class="w-3/5 flex justify-end">
          <div class="w-1/3 mr-6">
            <multiselect
                :icon="icons.filter"
                height="54px"
                background="white"
                :icon-width="25"
                width="12%"
                :value-t="valueSearchType"
                :option="searchType"
                @info="retourSearchType"
            />
          </div>

          <div class="w-1/3 mr-6">
            <multiselect
                :icon="icons.filter"
                height="54px"
                background="white"
                :icon-width="25"
                width="12%"
                :value-t="valueFiltre"
                :option="filtre"
                @info="retourFiltre"
            />
          </div>

          <div class=" w-1/3">
            <bouton
                label="Faire un devis"
                :icon="icons.add"
                radius="5.4px"
                weight="600"
                size="17px"
                height="54px"
                @oga="retourClick"
            />
          </div>
        </div>
      </div>


      <div class="flex justify-end">
        <div class="w-2/5 mt-6">
          <inputo :icon="icons.search" @info="retourSearch" height="56px" placeholder="Votre recherche ici" />
        </div>
      </div>

      <div class="mt-6">
        <global-view
            :filtre="valueFiltre"
            :searchType="valueSearchType"
            :search="recherche"
          v-if="!reloadSession"
          @info="retourEtape"
          @user="retourUser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import back from '../../assets/icons/backBut.svg'
import bouton from '../../component/helper/add/button'
import globalView from '../../component/cdevs/devis/globalData'
import add from '../../assets/icons/add.svg'
import multiselect from '../../component/helper/form/multiselect'
import inputo from '../../component/helper/form/input'
import search from '../../assets/icons/search.svg'

export default {
  name: "Index",

  components: {
    bouton,
    globalView,
    multiselect,
    inputo
  },

  data () {
    return {
      icons: {
        back,
        add,
        search
      },
      activeClient: false,
      reloadSession: false,
      selectedItem: null,
      user: null,
      filtre: ['Tout les devis', 'En cours', 'Accepté', 'A rappeler', 'Devis expiré'],
      searchType: ['Aucun', 'Date d\'édition', 'Date d\'expiration'],
      valueSearchType: 'Type de recherche',
      valueFiltre: 'Filtrer ici',
      recherche: ''
    }
  },

  created () {
    this.$store.dispatch('saveQuote', null)
    this.$store.dispatch('saveBasket', [])
    this.$store.dispatch('saveCoupon', null)
    this.$store.dispatch('saveUpdate', null)
    this.$store.dispatch('saveDevisUser', null)
  },

  methods: {
    retourClick () {
      this.$router.push('/nouveauDevis')
    },

    retourSearch (answer) {
      this.recherche = answer
    },

    retourUser (answer) {
      this.user = answer
    },

    retourFiltre (answer) {
      this.valueFiltre = answer
    },

    retourSearchType (answer) {
      this.valueSearchType = answer
    },

    retourEtape (answer) {
      this.selectedItem = answer
      this.activeDetail = true
    },
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
</style>
